<template>
    <b-row class="match-height">
        <b-col
            cols="12"
        >
            <p class="mb-2">
                <span>Módulo de datos estadísticos (métricas, reportes, etc.)</span>
            </p>
        </b-col>
        <!-- <b-col cols="12">
            <chartjs-bar-chart-2 />
        </b-col>
        <b-col cols="12">
            <chartjs-bar-chart-3 />
        </b-col> -->
        <b-col cols="12">
            <chartjs-bar-chart-4 />
        </b-col>
        <b-col cols="12">
            <chartjs-doughnut-chart />
        </b-col>
        <b-col cols="12">
            <chartjs-bar-chart />
        </b-col>
    </b-row>
</template>

<script>

    import ChartjsDoughnutChart from './ChartjsDoughnutChart.vue';
    import ChartjsBarChart from './ChartjsBarChart.vue';
    import ChartjsBarChart2 from './ChartjsBarChart2.vue';
    import ChartjsBarChart3 from './ChartjsBarChart3.vue';
    import ChartjsBarChart4 from './ChartjsBarChart4.vue';
    import { BRow, BCol, BLink } from 'bootstrap-vue';

    export default {
        components: {
            BRow,
            BCol,
            BLink,
            ChartjsBarChart,
            'chartjs-bar-chart-2': ChartjsBarChart2,
            'chartjs-bar-chart-3': ChartjsBarChart3,
            'chartjs-bar-chart-4': ChartjsBarChart4,
            ChartjsDoughnutChart
        }
    }

</script>