<template>
    <b-row>
        <b-col lg="6">
            <b-card title="Aprobados vs Desaprobados">
                <chartjs-component-doughnut-chart
                    :height="275"
                    :data="data"
                    :options="chartjsData.doughnutChart.options"
                    class="mb-3"
                />
                <div
                    v-for="(stock,index) in stockData"
                    :key="stock.device"
                    :class="index < stockData.length-1 ? 'mb-1':''"
                    class="d-flex justify-content-between"
                >
                    <div class="d-flex align-items-center">
                        <feather-icon
                            :icon="stock.symbol"
                            size="16"
                            :class="stock.color"
                        />
                        <span class="font-weight-bold ml-75 mr-25">{{ stock.device }}</span>
                        <span>- {{ stock.percentage }}%</span>
                    </div>
                </div>
            </b-card>
        </b-col>
        <b-col lg="6">
            <b-card no-body>
                <b-card-body>
                    <b-row>
                        <b-col md="12" v-if="$can('all', 'manage')">
                            <b-form-group
                                label="Seleccionar cliente"
                                label-for="customer"
                            >
                                <v-select
                                    id="customer"
                                    v-model="customer"
                                    label="name"
                                    placeholder="-- Seleccione un cliente --"
                                    :options="customers"
                                    trim
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group
                                label="Seleccionar curso(s)"
                                label-for="course"
                            >
                                <v-select
                                    id="course"
                                    v-model="coursesArray"
                                    multiple
                                    label="name"
                                    placeholder="-- Seleccione uno o más cursos --"
                                    :options="courses"
                                    trim
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-button variant="primary" block @click="getData">
                                <feather-icon
                                    icon="FilterIcon"
                                    size="16"
                                />
                                FILTRAR
                            </b-button>
                        </b-col>
                    </b-row>
                    <hr>
                    <json-excel :data="firstTableExport()" name="aprobados_y_desaprobados.xls"></json-excel>
                    <table class="table">
                        <thead class="thead-dark">
                        <tr>
                            <th>ITEM</th>
                            <th>APROBADO/DESAPROBADO</th>
                            <th>CANTIDAD</th>
                            <th>%</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in stockData" :key="index">
                            <td v-text="index+1"></td>
                            <td v-text="item.device"></td>
                            <td v-text="item.count"></td>
                            <td v-text="item.percentageText"></td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colspan="2" class="text-right font-weight-bold">TOTAL</td>
                            <td class="bg-yellow-table" v-text="tableTotal"></td>
                            <td class="bg-yellow-table">100%</td>
                        </tr>
                        </tfoot>
                    </table>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>

    import { BCard, BCardHeader, BCardBody, BCardTitle, BRow, BCol, BLink, BFormGroup, BButton } from 'bootstrap-vue';
    import ChartjsComponentDoughnutChart from './charts-components/ChartjsComponentDoughnutChart.vue';
    import JsonExcel from "./download-excel";
    import chartjsData from './chartjsData';
    import vSelect from 'vue-select';
    import axios from 'axios';

    export default {
        components: {
            ChartjsComponentDoughnutChart,
            BCard,
            BRow,
            BCol,
            BLink,
            BFormGroup,
            BButton,
            BCardHeader,
            BCardBody,
            BCardTitle,
            vSelect,
            JsonExcel
        },
        data() {
            return {
                coursesArray: [],
                courses: [],
                customer: null,
                customers: [],
                chartjsData,
                table: [],
                tableTotal: 0,
                data: null,
                stockData: []
            }
        },
        watch: {
            customer (newValue){

                this.coursesArray = [];
                this.courses = [];

                if (newValue){
                    this.getCourses();
                }
            }
        },
        beforeMount (){
            this.getCustomers();
            this.getData();
        },
        mounted (){
            let role = JSON.parse(localStorage.getItem('userData')).role;
            if (role == 'client'){
                this.customer = {
                    id: JSON.parse(localStorage.getItem('userData')).customer
                };
            }
        },
        methods: {
            async getCustomers (){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/customers/select`);
                this.customers = response.data;
            },
            async getCourses (){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/courses/select-filtered?customer=${this.customer.id}`);
                this.courses = response.data;
            },
            async getData (){

                let coursesIds = [];

                this.coursesArray.map(item => {
                    coursesIds.push(item.id);
                });

                let customerId = this.customer ? this.customer.id : '';

                let response = await axios.get(`${process.env.VUE_APP_WEB}/api/statistics/approved-disapproved?customer=${customerId}&courses=${coursesIds}`);
                this.stockData = [];
                this.table = response.data.data;
                this.stockData.push(response.data.data[0]);
                this.stockData.push(response.data.data[1]);
                this.data = {
                    datasets: [
                        {
                            labels: [response.data.data[1].device, response.data.data[0].device],
                            data: [parseFloat(response.data.data[1].percentage), parseFloat(response.data.data[0].percentage)],
                            backgroundColor: this.chartjsData.doughnutChart.data.datasets[0].backgroundColor,
                            borderWidth: 0,
                            pointStyle: 'rectRounded'
                        }
                    ]
                };
                this.tableTotal = response.data.data[1].count + response.data.data[0].count;
            },
            firstTableExport (){

                let array = [];

                for (let a=0; a<this.table.length; a++)
                {
                    let objBody = {
                        'ITEM': a+1,
                        'APROBADO / DESAPROBADO': this.table[a]["device"],
                        'CANTIDAD': this.table[a]["count"],
                        '%': this.table[a]["percentageText"]
                    };

                    array.push(objBody);
                }

                let objFoot = {
                    'ITEM': '',
                    'APROBADO / DESAPROBADO': 'TOTAL',
                    'CANTIDAD': this.tableTotal,
                    '%': '100%'
                };

                array.push(objFoot);

                return array;
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>

    table {
        text-align: center;
    }

    table th {
        text-transform: uppercase;
    }

    table th, table td {
        vertical-align: middle;
    }

    table tbody tr td:nth-child(2) {
        text-align: left;
    }

    .subtitle-filter {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .bg-yellow-table {
        background-color: rgba(255, 255, 0, 1);
        font-weight: bold;
    }

    .table-avg tbody tr td:nth-child(3) {
        text-align: left;
    }

</style>
