<template>
    <b-row>
        <b-col md="12">
            <b-card no-body>
                <b-card-header>
                    <b-card-title>Video introductorio del módulo (reproducciones)</b-card-title>
                    <div class="d-flex align-items-center">
                        <feather-icon
                            icon="CalendarIcon"
                            size="16"
                        />
                        <flat-pickr
                            @on-change="doSomethingOnChange"
                            v-model="rangePicker"
                            :config="config"
                            class="form-control flat-picker bg-transparent border-0 shadow-none"
                            placeholder="YYYY-MM-DD"
                        />
                    </div>
                </b-card-header>

                <b-card-body>
                    <chartjs-component-bar-chart
                        :height="400"
                        :data="data"
                        :options="chartjsData.latestBarChart.options"
                    />
                </b-card-body>
            </b-card>
        </b-col>
        <b-col lg="12">
            <b-card no-body>
                <b-card-body>
                    <b-row>
                        <b-col md="12" v-if="$can('all', 'manage')">
                            <b-form-group
                                label="Seleccionar cliente"
                                label-for="customer"
                            >
                                <v-select
                                    id="customer"
                                    v-model="customer"
                                    label="name"
                                    placeholder="-- Seleccione un cliente --"
                                    :options="customers"
                                    trim
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group
                                label="Seleccionar curso(s)"
                                label-for="course"
                            >
                                <v-select
                                    id="course"
                                    v-model="coursesArray"
                                    multiple
                                    label="name"
                                    placeholder="-- Seleccione uno o más cursos --"
                                    :options="courses"
                                    trim
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group
                                label="Seleccionar modulo(s)"
                                label-for="module"
                            >
                                <v-select
                                    id="module"
                                    v-model="modulesArray"
                                    multiple
                                    label="name"
                                    placeholder="-- Seleccione uno o más módulos --"
                                    :options="modules"
                                    trim
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-button variant="primary" block @click="getData">
                                <feather-icon
                                    icon="FilterIcon"
                                    size="16"
                                />
                                FILTRAR
                            </b-button>
                        </b-col>
                    </b-row>
                    <hr>
                    <json-excel :data="firstTableExport()" name="visualizaciones_video_introductorio_modulo.xls"></json-excel>
                    <table class="table">
                        <thead class="thead-dark">
                            <tr>
                                <th>ITEM</th>
                                <th>FECHA</th>
                                <th>VISUALIZACIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in table" :key="index">
                                <td v-text="index+1"></td>
                                <td v-text="item.date"></td>
                                <td v-text="item.total"></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="2" class="text-right font-weight-bold">TOTAL</td>
                                <td class="bg-yellow-table" v-text="tableTotal"></td>
                            </tr>
                        </tfoot>
                    </table>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>

    import { BCard, BCardHeader, BCardBody, BCardTitle, BRow, BCol, BLink, BFormGroup, BButton } from 'bootstrap-vue';
    import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue';
    import flatPickr from 'vue-flatpickr-component';
    import {Spanish} from 'flatpickr/dist/l10n/es';
    import JsonExcel from "./download-excel";
    import chartjsData from './chartjsData';
    import vSelect from 'vue-select';
    import axios from 'axios';

    export default {
        components: {
            BCard,
            BRow,
            BCol,
            BLink,
            BCardHeader,
            BCardBody,
            BCardTitle,
            flatPickr,
            JsonExcel,
            BFormGroup,
            BButton,
            vSelect,
            ChartjsComponentBarChart
        },
        data() {
            return {
                config: {
                    mode: 'range',
                    locale: Spanish
                },

                minDate: null,
                maxDate: null,
                rangePicker: [],

                coursesArray: [],
                courses: [],
                customer: null,
                customers: [],
                modulesArray: [],
                modules: [],

                chartjsData,
                table: [],
                tableTotal: 0,
                data: null
            }
        },
        watch: {
            customer (newValue){

                this.coursesArray = [];
                this.courses = [];
                this.modulesArray = [];
                this.modules = [];

                if (newValue){
                    this.getCourses();
                }
            },
            coursesArray (newValue) {

                this.modulesArray = [];
                this.modules = [];

                if (newValue){
                    this.getModules();
                }
            }
        },
        beforeMount (){
            let today = new Date();
            today.setDate(today.getDate() - 14);
            this.minDate = today.toISOString().split('T')[0];
            this.maxDate = new Date().toISOString().split('T')[0];

            this.rangePicker.push(this.minDate);
            this.rangePicker.push(this.maxDate);

            this.getCustomers();
            this.getData();
        },
        mounted (){
            let role = JSON.parse(localStorage.getItem('userData')).role;
            if (role == 'client'){
                this.customer = {
                    id: JSON.parse(localStorage.getItem('userData')).customer
                };
            }
        },
        methods: {
            doSomethingOnChange (){
                if (typeof this.rangePicker === 'string')
                {
                    let rangeDates = this.rangePicker.split( 'a ');
                    this.minDate = rangeDates[0];
                    this.maxDate = rangeDates[1];

                    this.getData();
                }
            },
            async getCustomers (){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/customers/select`);
                this.customers = response.data;
            },
            async getCourses (){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/courses/select-filtered?customer=${this.customer.id}`);
                this.courses = response.data;
            },
            async getModules (){

                let coursesIds = [];
                this.coursesArray.map(item => {
                    coursesIds.push(item.id);
                });

                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/modules/select-filtered?courses=${coursesIds}`);
                this.modules = response.data;
            },
            async getData (){

                let coursesIds = [];
                this.coursesArray.map(item => {
                    coursesIds.push(item.id);
                });

                let modulesIds = [];
                this.modulesArray.map(item => {
                    modulesIds.push(item.id);
                });

                let customerId = this.customer ? this.customer.id : '';

                let response = await axios.get(`${process.env.VUE_APP_WEB}/api/statistics/module/view-videos?minDate=${this.minDate}&maxDate=${this.maxDate}&customer=${customerId}&courses=${coursesIds}&modules=${modulesIds}`);
                this.table = response.data.data;

                let datasetsData = [];
                let dataLabels = [];
                let tableTotal = 0;

                response.data.data.map(item => {
                    dataLabels.push(item.date);
                    datasetsData.push(item.total);
                    tableTotal += item.total;
                });

                this.data = {
                    labels: dataLabels,
                    datasets: [
                        {
                            data: datasetsData,
                            backgroundColor: this.chartjsData.latestBarChart.data.datasets[0].backgroundColor,
                            borderColor: 'transparent'
                        }
                    ]
                };

                this.tableTotal = tableTotal;
            },
            firstTableExport (){

                let array = [];

                for (let a=0; a<this.table.length; a++)
                {
                    let objBody = {
                        'ITEM': a+1,
                        'FECHA': this.table[a]["date"],
                        'VISUALIZACIONES': this.table[a]["total"]
                    };

                    array.push(objBody);
                }

                let objFoot = {
                    'ITEM': '',
                    'FECHA': 'TOTAL',
                    'VISUALIZACIONES': this.tableTotal
                };

                array.push(objFoot);

                return array;
            }
        }
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>

    table {
        text-align: center;
    }

    table th {
        text-transform: uppercase;
    }

    table th, table td {
        vertical-align: middle;
    }

    table tbody tr td:nth-child(2) {
        text-align: left;
    }

    .subtitle-filter {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .bg-yellow-table {
        background-color: rgba(255, 255, 0, 1);
        font-weight: bold;
    }

    .table-avg tbody tr td:nth-child(3) {
        text-align: left;
    }

</style>
