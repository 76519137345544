<template>
    <export-excel
        v-if="data.length"
        class="btn btn-outline-success"
        :data="data"
        :name="name">
        EXPORTAR EN EXCEL
    </export-excel>
    <b-button variant="outline-danger" v-else>
        NO EXISTE DATA PARA EXPORTAR
    </b-button>
</template>

<script>

    import { BButton } from 'bootstrap-vue';
    import excel from 'vue-excel-export';
    import Vue from 'vue';

    Vue.use(excel);

    export default {
        components: {
            BButton
        },
        props: ['data', 'name']
    }

</script>

<style scoped>

    .btn {
        display: block;
        width: 100%;
        margin-bottom: 1rem !important;
        border-radius: 0.25rem !important;
        font-weight: bold;
    }

    .btn.btn-outline-success {
        cursor: pointer;
    }

    .btn i {
        margin-right: .5rem !important;
    }

</style>
